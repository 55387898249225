<template>
  <v-app>
    <header :class="{ classB: scrollPosition > 100 }">
      <div class="logo">
        <a href="#top"
          ><span
            class="icon-logo"
            :class="{ color_blue: scrollPosition > 100 }"
          ></span
        ></a>
      </div>
      <div class="links">
        <ul>
          <li>
            <a
              :class="{ color_blue: scrollPosition > 100 }"
              v-scroll-to="{
                el: '#services',
                offset: -70,
              }"
              >Serviços</a
            >
          </li>
          <li>
            <a
              :class="{ color_blue: scrollPosition > 100 }"
              v-scroll-to="{
                el: '#about',
                offset: -70,
              }"
              >Sobre Nós</a
            >
          </li>
          <li>
            <a
              :class="{ color_blue: scrollPosition > 100 }"
              v-scroll-to="{
                el: '#links',
                offset: -300,
              }"
              >Links Úteis</a
            >
          </li>
          <li>
            <a
              :class="{
                color_blue: scrollPosition > 100,
              }"
              v-scroll-to="{
                el: '#contacts',
                offset: -70,
              }"
              >Contacte-nos</a
            >
          </li>
        </ul>
      </div>
    </header>

    <v-main>
      <router-view></router-view>
    </v-main>

    <footer>
      <div class="logo">
        <a href="#top"><span class="icon-logo"></span></a>
      </div>
      <div class="links">
        <p>
          <a
            v-scroll-to="{
              el: '#contacts',
              offset: -70,
            }"
            >Contactos</a
          >
        </p>
        <!-- <p><a v-on:click="dialog = true">Política de Privacidade</a></p> -->
        <p>
          Todos os direitos reservados © HMGC {{ new Date().getFullYear() }}
        </p>
        <a href="#top"><img :src="img_url + 'arrow.png'" /></a>
      </div>
    </footer>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="#064f8d">
        <v-btn icon dark v-on:click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Política de Privacidade</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items> </v-toolbar-items>
      </v-toolbar>
      <div class="politica">
        <h4>1. Título</h4>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce finibus
          ipsum sit amet cursus cursus. Nam ut elit porta, sollicitudin purus
          sed, aliquet erat. Phasellus at augue facilisis, sodales justo eget,
          luctus dui. Maecenas congue a dolor a lobortis. Vestibulum sed
          sagittis arcu, suscipit condimentum nunc. Quisque rutrum, purus non
          malesuada viverra, neque felis laoreet eros, vel interdum ipsum erat
          quis libero. Maecenas fringilla ut lacus nec condimentum. Sed faucibus
          vestibulum justo. Interdum et malesuada fames ac ante ipsum primis in
          faucibus. Vivamus at arcu in ex elementum ullamcorper. Praesent sem
          nibh, consequat in tincidunt ut, posuere eget turpis. Pellentesque nec
          ante nisi. Proin molestie dapibus lacus tincidunt convallis. Ut
          consectetur, nunc et efficitur pulvinar, metus nisi maximus sem, et
          venenatis mauris nulla nec erat. Fusce elit quam, ullamcorper sit amet
          maximus non, eleifend eget quam. Praesent ullamcorper, justo sit amet
          rutrum tempus, leo nisi tristique neque, eget bibendum risus lorem ut
          massa. Sed quis odio ornare, vestibulum eros at, venenatis turpis. Nam
          venenatis non metus sodales dignissim. Phasellus at iaculis mauris,
          vitae hendrerit urna. Vivamus sit amet nibh at justo egestas
          consectetur. Vestibulum et consectetur lacus, in sollicitudin elit.
          Nam in viverra ligula, at posuere nunc. Nunc nunc risus, sodales in
          eros a, laoreet hendrerit augue. Aenean eget ligula vel neque lobortis
          facilisis. Duis sodales pretium nulla. Curabitur iaculis neque et
          vehicula elementum. Morbi accumsan sodales erat, at venenatis tortor
          dictum eget. Phasellus feugiat accumsan urna id luctus. In vel
          ultricies elit. Proin vitae rutrum risus. Nulla rhoncus ultricies odio
          sit amet dictum. Nunc tincidunt sit amet mi ac viverra. Quisque eu
          semper justo. Integer iaculis nulla eget magna imperdiet consequat.
          Maecenas sed enim sed quam viverra hendrerit vel sed enim. Curabitur
          egestas nisi id nunc vehicula, et ultrices lacus semper. Nunc
          facilisis aliquet sollicitudin. Phasellus sollicitudin diam ac nunc
          sodales, at porttitor lectus gravida. Sed lacus nisl, dignissim et
          tortor sed, dictum convallis eros. Sed sed mi ex. Etiam aliquam nibh
          eu sodales tincidunt. Mauris nibh ante, rutrum in ex ac, sollicitudin
          fermentum mi. Nullam et varius urna. Fusce id augue pellentesque,
          varius nibh tincidunt, convallis orci. Duis purus quam, rhoncus id
          ultricies nec, efficitur in risus. Duis finibus diam tristique, mollis
          ipsum a, convallis ligula. Aenean quis velit lacus. Quisque sit amet
          auctor tellus, et lobortis mauris. Vestibulum euismod ligula ipsum,
          sed consectetur ligula egestas et. Vestibulum sed dui ut est sagittis
          molestie at ut mauris. Vestibulum sit amet sapien nibh. Suspendisse
          laoreet dolor a hendrerit aliquet. Morbi ex augue, facilisis in luctus
          consectetur, luctus et neque. Suspendisse vulputate elit eget ligula
          rutrum, in ultricies diam accumsan.
        </p>
        <h4>2. Título</h4>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce finibus
          ipsum sit amet cursus cursus. Nam ut elit porta, sollicitudin purus
          sed, aliquet erat. Phasellus at augue facilisis, sodales justo eget,
          luctus dui. Maecenas congue a dolor a lobortis. Vestibulum sed
          sagittis arcu, suscipit condimentum nunc. Quisque rutrum, purus non
          malesuada viverra, neque felis laoreet eros, vel interdum ipsum erat
          quis libero. Maecenas fringilla ut lacus nec condimentum. Sed faucibus
          vestibulum justo. Interdum et malesuada fames ac ante ipsum primis in
          faucibus. Vivamus at arcu in ex elementum ullamcorper. Praesent sem
          nibh, consequat in tincidunt ut, posuere eget turpis. Pellentesque nec
          ante nisi. Proin molestie dapibus lacus tincidunt convallis.
        </p>
      </div>
    </v-card>
  </v-dialog>

  </v-app>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";
export default {
  mixins: [computedMixins, methodsMixins],
  name: "App",

  components: {
  },

  data: () => ({
    scrollPosition: null,
    dialog: false,
  }),
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },

  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

html {
  scroll-behavior: smooth;
  position: relative;

  i {
    font-style: italic !important;
  }

  ol,
  ul {
    padding-left: 0 !important;
    .description {
      li {
        margin-bottom: 5px;
        a {
          color: black;
          &:hover {
            font-weight: 600;
          }
        }
      }
    }
  }

  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    padding: 0 50px;
    transition: all 0.1s ease;
    @include responsive(0, 768px) {
      padding: 0px;
    }
    .logo {
      width: 100px;
      display: inline-block;
      vertical-align: middle;
      @include responsive(0, 768px) {
        width: 100%;
        text-align: center;
      }
      a {
        text-decoration: none;
        .icon-logo {
          font-size: 65px;
          line-height: 100px;
          color: white;
          @include responsive(0, 768px) {
            font-size: 60px;
            line-height: 90px;
          }
        }
      }
    }
    .links {
      width: calc(100% - 100px);
      display: inline-block;
      text-align: right;
      vertical-align: middle;
      @include responsive(0, 768px) {
        width: 100%;
        text-align: center;
      }
      ul {
        margin: 0;
        padding: 0;
        li {
          display: inline-block;
          @include responsive(0, 768px) {
            text-align: center;
          }
          a {
            color: white;
            margin-left: 30px;
            font-weight: $bold;
            text-decoration: none;
            @include responsive(0, 768px) {
              margin-left: 10px;
              margin-right: 10px;
              font-size: 14px;
            }
            @include responsive(0, 768px){
              margin-left: 5px;
              margin-right: 5px;
            }
            &:hover {
              color: #064f8d;
            }
          }
        }
      }
    }
    &.classB {
      background-color: white;
      -webkit-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.35);
      -moz-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.35);
      box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.35);
      @include responsive(0, 768px) {
        padding: 10px 0;
      }
      .color_blue {
        color: #064f8d !important;
        font-size: 14px !important;
        &.icon-logo {
          line-height: 70px !important;
          font-size: 50px !important;
        }
        &:hover {
          color: #5dbe7a !important;
        }
      }
    }
  }
  footer {
    background-color: #f2f2f3;
    padding: 0 50px;
    @include responsive(0, 768px) {
      text-align: center;
      padding: 20px 50px;
    }

    .logo {
      width: 100px;
      display: inline-block;
      vertical-align: middle;
      @include responsive(0, 768px) {
        width: 100%;
      }
      a {
        text-decoration: none;
        .icon-logo {
          font-size: 50px;
          line-height: 80px;
          color: #064f8d !important;
          @include responsive(0, 768px) {
            line-height: 70px;
          }
        }
      }
    }
    .links {
      width: calc(100% - 100px);
      display: inline-block;
      text-align: right;
      vertical-align: middle;
      @include responsive(0, 768px) {
        width: 100%;
        text-align: center;
      }
      p {
        display: inline-block;
        font-size: 12px;
        vertical-align: middle;
        margin-right: 20px;
        margin-bottom: 0;
        font-weight: $light;
        @include responsive(0, 768px) {
          display: block;
          margin-right: 0px;
        }
        a {
          color: black;
          font-weight: $semi-bold;
          &:hover {
            font-weight: $regular;
          }
        }
      }
      a {
        vertical-align: middle;
        img {
          width: 40px;
        }
      }
    }
  }
}

.politica {
  width: 90%;
  margin: 60px auto;
  h4 {
    text-align: left;
    margin-top: 70px;
  }
}
</style>

<template>
  <section id="services" class="services">
    <div class="title">
      <h2>Serviços</h2>
      <p>Contamos com uma equipa multidisciplinar que vai desde Contabilistas Certificados e Técnicos de Contabilidade <i>in house</i> que articulam com os melhores consultores externos. Os principais serviços que prestamos são os seus pilares que materializam a visão da empresa.</p>
    </div>
    <div class="mosaico">
      <ul>
        <li v-for="(service, i) in services" :key="i">
          <div>
            <span
              v-bind:style="{ color: service.color }"
              class="icon-logo"
            ></span>
            <h4 v-bind:style="{ color: service.color }">{{ service.name }}</h4>
            <a
              v-on:click="id = service.id"
              v-scroll-to="{
                el: '#description',
                offset: -70,
              }"
              class="btn-outline black-btn"
              >Saiba Mais</a
            >
          </div>
        </li>
      </ul>
    </div>
    <div id="description" class="description">
      <ul>
        <li v-for="(service, j) in services" :key="j">
          <div
            v-if="id == services[j].id"
            v-bind:style="{ backgroundColor: service.color }"
          >
            <div class="prev">
              <a
                v-on:click="
                  j > 0 ? (id = services[j - 1].id) : (id = services[6].id)
                "
                v-scroll-to="''"
              >
                <img src="@/assets/images/prev.png" />
              </a>
            </div>
            <div class="next">
              <a
                v-on:click="
                  j < 6 ? (id = services[j + 1].id) : (id = services[0].id)
                "
                v-scroll-to="''"
              >
                <img src="@/assets/images/next.png" />
              </a>
            </div>
            <div class="container-desc">
              <span class="icon-logo"></span>
              <h3>{{ service.name }}</h3>
              <p v-html="service.description"></p>
              <a href="#contacts" class="btn-outline">Contacte-nos</a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      id: "1",
      services: [
        {
          id: "1",
          name: "Contabilidade Financeira e Analítica",
          color: "#064f8d",
          description:
            "A contabilidade, através das demonstrações financeiras, continua a ser a melhor ferramenta de análise da realidade de uma empresa.<br>Na HMGC o serviço de contabilidade pode ser executado nas nossas ou nas instalações do cliente. Dispomos de todas ferramentas tecnologias que permitam a prestação de serviços de outsourcing de forma remota e em condições segurança e sigilo.",
        },
        {
          id: "2",
          name: "Consultoria Fiscal",
          color: "#f36f5e",
          description: "A fiscalidade é cada vez mais um fator determinante na tomada de decisão não só através do cumprimento das obrigações fiscais como no planeamento e análise da realidade fiscal de cada sujeito.<br>A consultoria fiscal é o segundo pilar dos nossos 5 serviços. Acompanhamos e planeamos antecipando problemas futuros à luz do conhecemos hoje.<br>Muito embora tenhamos um departamento exclusivamente dedicado ao contencioso fiscal no limite legal das nossas competências, privilegiamos o aconselhamento que tende a prevenir esse mesmo contencioso.",
        },
        {
          id: "4",
          name: "Serviços Administrativos",
          color: "#5dbe7a",
          description: "No sentido de criar um ambiente logístico mais facilitado para os seus clientes a HMGC disponibiliza um conjunto de serviços para que o seu cliente possa focar-se a 100% no seu negócio e deixar a operacionalização dos serviços administrativos nos nossos escritórios.<br>●	sede fiscal de sociedades;<br>●	emissão de faturas ou recibos verdes;<br>●	aluguer de salas de reuniões;<br>●	controlo de contas correntes;<br*	Representação Fiscal para não Residentes",
        },
        {
          id: "3",
          name: "Processamento Salarial",
          color: "#064f8d",
          description: "O processamento salarial, por estar associado ao rendimento de cada pessoa, torna este como um dos pilares do funcionamento de uma empresa.<br>Ajudamos a encontrar a melhor solução para que a empresa dentro do circunstancialismos do quadro legal existente, que  possa criar valor para o seu colaborador sem deixar analisar o custo para a empresa.",
        },
        {
          id: "5",
          name: "Formação",
          color: "#5dbe7a",
          description: '"A licenciatura é uma licença para estudar" é um dos apanágios da HMGC nesse sentido não só mantemos todos os nossos quadros atualizados em toda a componente contabilística e fiscal como lecionamos em mais do que uma instituição sobre as mesmas matérias.<br>Na HMGC dispomos de quadros qualificados para formação na área contabilística e fiscal.',
        },
        {
          id: "6",
          name: "Apoio à Gestão",
          color: "#f36f5e",
          description: "A HMGC ambiciona ser o parceiro preferencial dos seus clientes na sua tomada de decisão de gestão.<br>Munidos de toda a informação financeira e da realidade da empresa se próximos da gestão seremos, com certeza, os parceiros indicados para o ajudar na tomada de decisão do dia-a-dia.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.services {
  padding-top: 80px;
  text-align: center;
  @include responsive(0, 512px) {
    padding-top: 60px;
  }
  .title {
    max-width: 900px;
    margin: 0 auto;
    margin-bottom: 80px;
    width: 90%;
    @include responsive(0, 512px) {
    margin-bottom: 60px;
  }
  }
  .mosaico {
    text-align: center;
    font-size: 0;
    ul {
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        width: 33.3%;
        text-align: center;
        height: 35vw;
        position: relative;
        @include responsive(0, 1024px) {
          width: 50%;
          height: 400px;
        }
        @include responsive(0, 512px) {
          width: 100%;
        }
        div {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 90%;
          max-width: 300px;
          @include responsive(0, 768px){
            width: 70%;
          }
          span {
            font-size: 130px;
            @include responsive(0, 1280px){
              font-size: 100px;
            }
            @include responsive(0, 768px){
              font-size: 80px;
              line-height: 130px;
            }
          }
          h4 {
            margin-top: -20px;
            min-height: 70px;
          }
          a {
            margin: 0 auto;
          }
        }
      }
    }
    li:nth-child(odd) {
      background-color: #ededed;
    }
    li:nth-child(even) {
      background-color: #f2f2f3;
    }
    li:nth-child(3){
      @include responsive(512px, 1024px){
        background-color: #f2f2f3;
      }
    }
    li:nth-child(4){
      @include responsive(512px, 1024px){
        background-color: #ededed;
      }
    }
  }
  .description {
    ul {
      li {
        div {
          min-height: 600px;
          position: relative;
          padding-top: 50px;
          @include responsive(0, 768px){
            padding-top: 30px;
          }
          @include responsive(0, 512px){
            min-height: 700px;
          }
          span {
            font-size: 80px;
            color: white;
          }
          h3 {
            margin: 0 auto;
            margin-bottom: 30px;
            max-width: 90%;
            @include responsive(0, 380px){
              font-size: 20px;
              line-height: 28px;
            }
          }
          p {
            color: white;
            max-width: 900px;
            margin: 0 auto;
            width: 80%;
          }
          a {
            margin: 0 auto;
            margin-top: 50px;
            margin-bottom: 30px;
          }
        }
      }
    }
    .prev,
    .next {
      position: absolute;
      width: 20px;
      top: 200px;
      height: 50px;
      cursor: pointer;
      z-index: 3;
      a {
        width: 100%;
        height: 100%;
        display: block;
        img {
          width: 100%;
        }
      }
      @include responsive(0, 512px) {
        top: 250px;
        width: 10px;
      }
    }
    .prev {
      left: 30px;
      @include responsive(0, 512px) {
        left: 15px;
      }
    }
    .next {
      right: 30px;
      @include responsive(0, 512px) {
        right: 15px;
      }
    }
  }
}
</style>
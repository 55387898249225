<template>
  <v-carousel
    class="banner"
    cycle
    hide-delimiters
    :show-arrows="banners.length > 1"
    height="100%"
  >
    <v-carousel-item
      v-for="(banner, i) in banners"
      :key="i"
      :src="img_url + banner.img"
    >
      <div class="banner-content">
        <h1 v-html="banner.title"></h1>
        <h3 v-html="banner.text"></h3>
        <a
          v-scroll-to="{
            el: '#contacts',
            offset: -70,
          }"
          class="btn-outline"
          >Contacte-nos</a
        >
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";
export default {
  mixins: [computedMixins, methodsMixins],

  data: () => ({
    model: 0,
    banners: [
      {
        title: "MISSÃO",
        text:
          "Ser líder na satisfação da totalidade dos nossos Parceiros, Clientes, Fornecedores e Colaboradores.",
        img: "banner1.jpg",
      },
      {
        title: "VISÃO",
        text:
          "Ser a empresa que melhor entende as necessidades de serviços dos seus clientes.<br>Aliar a inovação e o arrojo à prudência.",
        img: "banner3.jpg",
      },
      {
        title: "VALORES",
        text:
          "Rigor, Disciplina e Superação.<br>Só se constrói uma história de 60 anos se não transigirmos nos valores.",
        img: "banner2.jpg",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.banner {
  height: 100vh !important;
}
.banner-content {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 900px;
  @include responsive(0, 768px){
    width: 70%;
    top: 55%;
  }
  @include responsive(0, 380px){
    width: 65%;
  }
  a {
    margin: 0 auto;
  }
}

.v-window__container {
  height: 600px !important;
}
</style>

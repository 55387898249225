<template>
  <section class="contacts" id="contacts">
    <div class="map desktop">
      <a
        href="https://www.google.pt/maps/place/R.+Padre+Am%C3%A9rico+7,+1600-764+Lisboa/@38.7609729,-9.1809769,18z/data=!3m1!4b1!4m5!3m4!1s0xd1932d0147ec31d:0x7799fbd186d740ca!8m2!3d38.7609708!4d-9.1798826"
        target="_blank"
        ><div
          v-bind:style="{ backgroundImage: 'url(' + img_url + 'map.jpg)' }"
        ></div
      ></a>
    </div>
    <div class="form">
      <h2>Contacte-nos</h2>
      <div>
        <ul>
          <li>
            <v-text-field
              v-model="name"
              label="Nome*"
              dense
              color="black"
            ></v-text-field>
            <v-text-field
              v-model="email"
              label="Email*"
              dense
              color="black"
            ></v-text-field>
            <v-text-field
              v-model="phone"
              label="Telefone*"
              dense
              color="black"
            ></v-text-field>
            <v-text-field
              v-model="subject"
              label="Assunto*"
              dense
              color="black"
            ></v-text-field>
            <v-textarea
              v-model="message"
              label="Mensagem*"
              color="black"
              rows="3"
            ></v-textarea>
          </li>
          <!-- <li>
            <v-checkbox v-model="checkbox">
              <template v-slot:label>
                <div>
                  Declaro que li e aceito a Política de Privacidade.
                </div>
              </template>
            </v-checkbox>
          </li> -->
        </ul>
        <a @click="submeter()"  class="btn-outline black-btn">Enviar</a>
        <p class="error-msg">{{errMsg}}</p>
        <p>RUA PADRE AMÉRICO Nº7, 1ºDto<br />1600-548 Lisboa, Portugal</p>
        <br />
        <p><a href="mailto:geral@hmgc.pt">geral@hmgc.pt</a></p>
        <p><a href="tel:00351217950159">+351 217 950 159</a></p>
      </div>
    </div>
    <div class="map mobile">
      <a
        href="https://www.google.pt/maps/place/R.+Padre+Am%C3%A9rico+7,+1600-764+Lisboa/@38.7609729,-9.1809769,18z/data=!3m1!4b1!4m5!3m4!1s0xd1932d0147ec31d:0x7799fbd186d740ca!8m2!3d38.7609708!4d-9.1798826"
        target="_blank"
        ><div
          v-bind:style="{ backgroundImage: 'url(' + img_url + 'map.jpg)' }"
        ></div
      ></a>
    </div>
  </section>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";
export default {
  mixins: [computedMixins, methodsMixins],
  data() {
    return {
      checkbox: false,
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      errMsg: "",
    };
  },
  methods: {
    changeModal: async function (modal) {
      this.$emit("update-modal", modal);
    },
    openFooter: async function (modal) {
      event.preventDefault();
      this.$emit("open-footer", modal);
    },
    async submeter() {
      event.preventDefault()

      this.errMsg = ""

      let errorMsgs = {
          name: "Introduza o seu nome",
          email: "Introduza o seu email",
          phone: "Introduza o seu telefone",
          subject:"Introduza o assunto da sua mensagem",
          message: "Escreva a sua mensagem",
          success: "A sua mensagem foi enviada com sucesso! Será contactado brevemente",
          fail: "Houve um erro no envio da sua mensagem. Por favor tente novamente mais tarde ou entre em contacto através de um dos canais alternativos",
          sending: "A enviar..."
      } 

      if(this.name == "") {
        this.errMsg = errorMsgs.name
      }
      else if(this.email == "") {
        this.errMsg = errorMsgs.email
      }
      else if(this.phone == "") {
        this.errMsg = errorMsgs.phone
      }
      else if(this.subject == "") {
        this.errMsg = errorMsgs.subject
      }
      else if(this.message == "") {
        this.errMsg = errorMsgs.message
      }
      else {
        this.errMsg = errorMsgs.sending

        let mensagem = `
          <p><b>Nome: </b>${this.name}</p>
          <p><b>Email: </b>${this.email}</p>
          <p><b>Telefone: </b>${this.phone}</p>
          <p><b>Assunto: </b>${this.subject}</p>
          <p><b>Mensagem: </b><br>${this.message}</p>
        `

        let fd = new FormData()

        fd.append('mensagem', mensagem)

        var myHeaders = new Headers();

        var myInit = { 
          method: 'POST',
          headers: myHeaders,
          body: fd
        };

        let app = this

        fetch('/base_mailer/', myInit)
        .then(async function(response) {
          let res = await response.text()

          console.log(res)

          if(res == "OK") {
            app.errMsg = errorMsgs.success
          }
          else {
            app.errMsg = errorMsgs.fail
          }
        })

      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.contacts {
  width: 100%;
  .map {
    width: 50%;
    display: inline-block;
    height: 700px;
    vertical-align: middle;
    @include responsive(0, 768px) {
      width: 100%;
      height: 500px;
    }
    &.desktop {
      @include responsive(0, 768px) {
        display: none;
      }
    }
    &.mobile {
      display: none;
      @include responsive(0, 768px) {
        display: block;
      }
    }
    a {
      display: block;
      height: 100%;
      width: 100%;
      div {
        height: 100%;
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  .form {
    width: 50%;
    display: inline-block;
    padding: 0 50px;
    vertical-align: middle;
    @include responsive(0, 768px) {
      background-color: #fbfbfd;
      width: 100%;
      padding: 60px 5%;
      text-align: center;
    }
    h2 {
      text-align: left;
      @include responsive(0, 768px) {
        text-align: center;
      }
    }
    ul {
      li {
        width: 400px;
        color: black;
        @include responsive(0, 768px) {
          margin: 0 auto;
        }
        @include responsive(0, 512px) {
          width: 100%;
        }
      }
    }
    .error-msg {
      color: #f36f5e;
      font-size: 12px;
      font-weight: $semi-bold;
      min-height: 40px;
    }
    .btn-outline {
      margin-bottom: 30px;
      margin-top: 10px;
      @include responsive(0, 768px) {
        margin: 0 auto;
        margin-bottom: 30px;
        margin-top: 10px;
      }
    }
    p {
      margin-bottom: 0;
      line-height: 20px;
      font-weight: $bold;
      font-size: 14px;
      a {
        text-decoration: none;
        color: black;
        &:hover {
          font-weight: $regular;
        }
      }
    }
  }
}
</style>
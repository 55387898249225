<template>
<section>
  <Banner />
  <Services />
  <About />
  <Contacts />
</section>
 
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";
import Banner from "@/components/banner";
import Services from "@/components/serviços";
import About from "@/components/about";
import Contacts from "@/components/contacts";

export default {
  name: 'Home',
  mixins: [computedMixins, methodsMixins],
  components: {
    Banner,
    Services,
    About,
    Contacts,
  },
  data(){
    return{
      modal:''
    }
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    updateModal: async function (value) {
      this.modal = value;
    },
  },
}
</script>


<template>
  <section id="about" class="about">
    <ul>
      <li v-for="(item, i) in about" :key="i" class="desktop">
        <div
          class="img"
          v-if="item.type == 'img'"
          v-bind:style="{ backgroundImage: 'url(' + img_url + item.img + ')' }"
        ></div>
        <div :id="item.id" class="text" v-if="item.type == 'text'">
          <h2 v-if="item.title != ''" v-html="item.title"></h2>
          <div class="description" v-html="item.description"></div>
          <a
            v-if="item.btn != ''"
            class="btn-outline black-btn"
            v-scroll-to="{
              el: '#contacts',
              offset: -70,
            }"
            >{{ item.btn }}</a
          >
        </div>
      </li>
      <li v-for="(item, i) in about_mobile" :key="i" class="mobile">
        <div
          class="img"
          v-if="item.type == 'img'"
          v-bind:style="{ backgroundImage: 'url(' + img_url + item.img + ')' }"
        ></div>
        <div :id="item.id" class="text" v-if="item.type == 'text'">
          <h2 v-if="item.title != ''" v-html="item.title"></h2>
          <div class="description" v-html="item.description"></div>
          <a
            v-if="item.btn != ''"
            class="btn-outline black-btn"
            v-scroll-to="{
              el: '#contacts',
              offset: -70,
            }"
            >{{ item.btn }}</a
          >
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";
export default {
  mixins: [computedMixins, methodsMixins],

  data() {
    return {
      about: [
        {
          type: "text",
          title: "Sobre Nós",
          description:
            "<p>Somos o resultado de 60 anos de história e de trabalho fundada por Custódio Canelas.</p><p>Existimos e estudámos contabilidade desde os livros de contas a pagar e receber até ao SNC passando pelo POC e interagimos com a AT desde a Contribuição Industrial, Imposto de Transações, Imposto Profissional, Impostos Complementar e SISA até aos dias de hoje tratando de  IR, de IVA ou IMT.</p>",
          img: "",
          btn: "",
        },
        {
          type: "img",
          title: "",
          description: "",
          img: "about1.jpg",
          btn: "",
        },
        {
          type: "img",
          title: "",
          description: "",
          img: "about2.jpg",
          btn: "",
        },
        {
          type: "text",
          title: "",
          description:
            '<p>Assistimos e acompanhamos a informatização da contabilidade em computadores que funcionavam com disquetes de 5 ¼ e cartões perfurados e dispomos hoje de ferramentas tecnológicas de ultima geração que nos permitem estar perto do nosso cliente independentemente da distância.</p><p>Assim, somos o resultado de três gerações de contabilistas certificados e gestores que sempre procuraram ser pioneiros na prestação de serviços de apoio à gestão.</p><p><b>Resumimos a nossa atividade à nossa vocação: "Criar Valor ao cliente"<b/></p>',
          img: "",
          btn: "Contacte-nos",
        },
        {
          type: "text",
          title: "Links Úteis",
          description:
            "<ul><li> <a target='_blank' href='http://www.occ.pt'>OCC - Ordem dos Contabilistas Certificados</a></li><li> <a target='_blank' href='http://www.apeca.pt'>APECA - Associação Portuguesa Das Empresas De Contabilidade e Administração</a></li><li> <a target='_blank' href='http://www.apotec.pt'>APOTEC - Associação Portuguesa de Técnicos de Contabilidade</a></li><li> <a target='_blank' href='http://www.portaldasfinancas.gov.pt'>Portal das Finanças</a></li><li> <a target='_blank' href='http://www.seg-social.pt/inicio'>Segurança Social Directa</a></li></ul>",
          img: "",
          btn: "",
          id: "links",
        },
        {
          type: "img",
          title: "",
          description: "",
          img: "about3.jpg",
          btn: "",
        },
      ],
      about_mobile: [
        {
          type: "img",
          title: "",
          description: "",
          img: "about1.jpg",
          btn: "",
        },
        {
          type: "text",
          title: "Sobre Nós",
          description:
            "<p>Somos o resultado de 60 anos de história e de trabalho fundada por Custódio Canelas.</p><p>Existimos e estudámos contabilidade desde os livros de contas a pagar e receber até ao SNC passando pelo POC e interagimos com a AT desde a Contribuição Industrial, Imposto de Transações, Imposto Profissional, Impostos Complementar e SISA até aos dias de hoje tratando de  IR, de IVA ou IMT.</p>",
          img: "",
          btn: "",
        },
        {
          type: "img",
          title: "",
          description: "",
          img: "about2.jpg",
          btn: "",
        },
        {
          type: "text",
          title: "",
          description:
            '<p>Assistimos e acompanhamos a informatização da contabilidade em computadores que funcionavam com disquetes de 5 ¼ e cartões perfurados e dispomos hoje de ferramentas tecnológicas de ultima geração que nos permitem estar perto do nosso cliente independentemente da distância.</p><p>Assim, somos o resultado de três gerações de contabilistas certificados e gestores que sempre procuraram ser pioneiros na prestação de serviços de apoio à gestão.</p><p><b>Resumimos a nossa atividade à nossa vocação: "Criar Valor ao cliente"<b/></p>',
          img: "",
          btn: "Contacte-nos",
        },
        {
          type: "img",
          title: "",
          description: "",
          img: "about3.jpg",
          btn: "",
        },
        {
          type: "text",
          title: "Links Úteis",
          description:
            "<ul><li> <a target='_blank' href='http://www.occ.pt'>OCC - Ordem dos Contabilistas Certificados</a></li><li> <a target='_blank' href='http://www.apeca.pt'>APECA - Associação Portuguesa Das Empresas De Contabilidade e Administração</a></li><li> <a target='_blank' href='http://www.apotec.pt'>APOTEC - Associação Portuguesa de Técnicos de Contabilidade</a></li><li> <a target='_blank' href='http://www.portaldasfinancas.gov.pt'>Portal das Finanças</a></li><li> <a target='_blank' href='http://www.seg-social.pt/inicio'>Segurança Social Directa</a></li></ul>",
          img: "",
          btn: "",
          id: "links",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.about {
  ul {
    li {
      width: 50%;
      display: inline-block;
      vertical-align: top;
      height: 500px;
      position: relative;
      @include responsive(0, 768px) {
        width: 100%;
        height: auto;
      }
      &.desktop {
        @include responsive(0, 768px) {
          display: none;
        }
      }
      &.mobile {
        display: none;
        @include responsive(0, 768px) {
          display: block;
        }
      }
      .img {
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @include responsive(0, 768px) {
          height: 300px;
        }
      }
      .text {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 0 3vw;
        @include responsive(0, 768px) {
          margin: 0 auto;
          position: relative;
          top: auto;
          transform: translateY(0%);
          padding: 50px 0px;
          width: 90%;
        }
        h2 {
          text-align: left;
        }
        .description {
          p {
            text-align: left;
          }
          ul {
            padding-left: 0 !important;
            margin-left: 0 !important;
            li {
              a {
                text-decoration: underline;
                color: black !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>